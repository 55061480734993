import {Field} from 'formik'
import {countries} from '../../../../utils/countries'
import React from 'react'

const CreateFinancierGeneralInformation = ({errors, touched, setFileObject, setFieldValue}: any) => {
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    const file = event.target?.files ? event.target.files[0] : null
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result as string
        const base64Content = base64String.split(',')[1]
        const fileObj = {
          fileName: file.name,
          fileType: file.type,
          fileContent: base64Content,
          dateUploaded: new Date().toISOString(),
          fileSize: file.size,
        }
        setFileObject(fileObj)
        setFieldValue('generalInformation.logo', file)
      }
      reader.readAsDataURL(file)
    } else {
      setFieldValue('generalInformation.logo', null)
    }
  }
  return (
    <div className="current " data-kt-stepper-element="content">
      <div className="w-100">
        <div className="fv-row row mb-10 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span className="required">{`Financier Name`}</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="generalInformation.name"
              placeholder=""
            />

            {touched?.generalInformation?.name && (
              <div className="text-danger mt-2">{errors?.generalInformation?.name}</div>
            )}
          </div>
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Country</span>
            </label>
            <Field
              as="select"
              name="generalInformation.country"
              className="form-select select2-hidden-accessible py-lg-5 "
            >
              <option value="" disabled selected>
                Select Country
              </option>
              {countries.map((option) => (
                <option key={option.value} value={option.label}>
                  {option.label}
                </option>
              ))}
            </Field>

            {touched?.generalInformation?.country && (
              <div className="text-danger mt-2">{errors?.generalInformation?.country}</div>
            )}
          </div>
        </div>
        <div className=" fv-row row p-0">
          <div className="col-lg-6">
            {/*About the company */}
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>{`About Financier`}</span>
            </label>

            <Field
              as="textarea"
              className="form-control form-control-lg py-lg-5 about-textarea"
              name="generalInformation.about"
              placeholder=""
            />
            {touched?.generalInformation?.about && (
              <div className="text-danger mt-2">{errors?.generalInformation?.about}</div>
            )}
          </div>
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Region</span>
            </label>

            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="generalInformation.region"
              placeholder="Central"
            />

            {touched?.generalInformation?.region && (
              <div className="text-danger mt-2">{errors?.generalInformation?.region}</div>
            )}
          </div>
        </div>
        <div className="fv-row row p-0">
          <div className="col-lg-6 offset-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Company Logo</span>
            </label>
            <input
              type="file"
              className="form-control form-control-lg py-lg-5"
              name="generalInformation.logo"
              placeholder=""
              style={{width: '100%'}}
              onChange={(event) => handleFileChange(event, setFieldValue)}
              accept="image/*"
            />
            {touched?.generalInformation?.logo && (
              <div className="text-danger mt-2">{errors?.generalInformation?.logo}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateFinancierGeneralInformation
