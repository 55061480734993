import React from 'react'
import {Routes, Route, Navigate} from 'react-router'
import {
  Dashboard,
  FinancierPagesWrapper,
  Calls,
  Deals,
  Settings,
  Pipeline,
} from '../features/financiers/pages'
import {useAuth} from 'react-oidc-context'
import CandidatesDetailPage from '../features/financiers/pages/CandidatesDetailPage'
import CallsDetailsPage from '../features/financiers/pages/CallsDetails'
import {useIsFinancier, useIsGrantProvider} from '../../_theme/utils/authUtil'

const FinancierRoutes = () => {
  const auth = useAuth()
  const financierId = auth?.user?.profile?.financier_id
  const isFinancier = useIsFinancier()
  const isGrantProvider = useIsGrantProvider()
  return (
    <Routes>
      <Route path="financier/:financierId/*" element={<FinancierPagesWrapper />}>
        {isFinancier && <Route index path="pipeline" element={<Pipeline />} />}
        {isGrantProvider && <Route index path="calls" element={<Calls />} />}
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="calls/:callId" element={<CallsDetailsPage />} />
        <Route path="calls/:callId/applications/:stage/:applicationIndex" element={<CandidatesDetailPage />} />
        <Route path="deals" element={<Deals />} />
        <Route path="settings" element={<Settings />} />
      </Route>
      <Route path="/" element={isFinancier ? <Navigate to={`/financier/${financierId}/pipeline`} /> :
        <Navigate to={`/financier/${financierId}/calls`} />} />
      <Route path="auth/*" element={isFinancier ? <Navigate to={`/financier/${financierId}/pipeline`} /> :
        <Navigate to={`/financier/${financierId}/calls`} />} />
    </Routes>
  )
}

export default FinancierRoutes