import React, {FC} from 'react'
import {Formik, Form, Field} from 'formik'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_theme/helpers/components/KTSVG'
import * as Yup from 'yup'
import {
  useAddNewProjectMutation,
  useGetServiceProviderProjectsQuery,
} from '../ServiceProvidersApiSlice'
import {useParams} from 'react-router-dom'

interface Props {
  showModal: boolean
  handleClose: () => void
}

export const AssignProject: FC<Props> = ({showModal, handleClose}) => {
  const AssignProjectValidationSchema = Yup.object().shape({
    name: Yup.string().required('Project Name is required'),
    description: Yup.string().required('Project Description is required'),
    owner: Yup.string().required('Project Owner is required'),
    deliverables: Yup.string().required('Project Deliverable is required'),
    timeEstimation: Yup.string().required('Time estimate is required'),
  })

  const [addNewProject, {isLoading}] = useAddNewProjectMutation()
  const {id} = useParams<{id: string}>() as {id: string}
  const {data: serviceProviderProjectsState} = useGetServiceProviderProjectsQuery(id)
  const serviceProviderProjectsDetails = serviceProviderProjectsState?.entities[id]

  const initialValues = {
    ...serviceProviderProjectsDetails,
  }

  const handleSave = async (values: any) => {
    try {
      await addNewProject({
        name: values.name,
        description: values.description,
        owner: values.owner,
        deliverables: values.deliverables,
        timeEstimation: values.timeEstimation,
        providerId: id,
      }).unwrap()
      handleClose()
    } catch (err) {
    }
  }

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      id="kt_assign_project"
      aria-hidden="true"
      dialogClassName="h-auto me-5 w-350px"
    >
      <div className="modal-content" role="document">
        <Formik
          initialValues={initialValues || {}}
          validationSchema={AssignProjectValidationSchema}
          onSubmit={handleSave}
        >
          {({values, errors, touched, isValid}) => (
            <Form>
              <div className="modal-header pb-2">
                <h5 className="modal-title">Assign Project to Service Provider</h5>
                <div className="btn btn-sm btn-icon btn-active-color-danger" onClick={handleClose}>
                  <KTSVG path="/media/icons/duotone/arrows/arr061.svg" className="svg-icon-1" />
                </div>
              </div>
              <div className="modal-body pt-2">
                <div className="fv-row">
                  <div className="col-md-12 mb-3">
                    <label
                      htmlFor="name"
                      className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark required"
                    >
                      Project Name
                    </label>
                    <Field
                      type="text"
                      name="name"
                      className="form-control form-control-sm"
                      style={{color: '#616162', fontWeight: 300}}
                      placeholder=""
                    />
                    {touched.name && <div className="text-danger mt-2">{errors.name}</div>}
                  </div>
                </div>
                <div className="fv-row">
                  <div className="col-md-12 mb-3">
                    <label
                      htmlFor="description"
                      className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark required"
                    >
                      Project Description
                    </label>
                    <Field
                      as="textarea"
                      name="description"
                      className="form-control form-control-sm"
                      style={{color: '#616162', fontWeight: 300}}
                      placeholder=""
                    />
                    {touched.description && (
                      <div className="text-danger mt-2">{errors.description}</div>
                    )}
                  </div>
                </div>
                <div className="fv-row">
                  <div className="col-md-12 mb-3">
                    <label
                      htmlFor="owner"
                      className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark required"
                    >
                      Project Owner
                    </label>
                    <Field
                      type="text"
                      name="owner"
                      className="form-control form-control-sm"
                      style={{color: '#616162', fontWeight: 300}}
                      placeholder=""
                    />
                    {touched.owner && <div className="text-danger mt-2">{errors.owner}</div>}
                  </div>
                </div>
                <div className="fv-row">
                  <div className="col-md-12 mb-3">
                    <label
                      htmlFor="deliverables"
                      className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark required"
                    >
                      Project Deliverables
                    </label>
                    <Field
                      type="text"
                      name="deliverables"
                      className="form-control form-control-sm"
                      style={{color: '#616162', fontWeight: 300}}
                      placeholder=""
                    />
                    {touched.deliverables && (
                      <div className="text-danger mt-2">{errors.deliverables}</div>
                    )}
                  </div>
                </div>
                <div className="fv-row">
                  <div className="col-md-12 mb-3">
                    <label
                      htmlFor="timeEstimation"
                      className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark required"
                    >
                      Time Estimates
                    </label>
                    <Field
                      as="select"
                      name="timeEstimation"
                      className="form-select form-select-sm select2-hidden-accessible align-items-center"
                      style={{color: '#616162', fontWeight: 300}}
                      multiple={false}
                    >
                      <option value="">Select</option>
                      <option value="4 Months">4 Months</option>
                      <option value="6 Months">6 Months</option>
                      <option value="12 Months">12 Months</option>
                      <option value="24 Months">24 Months</option>
                    </Field>
                    {touched.timeEstimation && (
                      <div className="text-danger mt-2">{errors.timeEstimation}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer mt-0">
                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => {
                    handleSave(values)
                  }}
                  disabled={!isValid || isLoading}
                >
                  <span className="indicator-label">
                    {!isLoading && 'Save'}
                    {isLoading && (
                      <>
                        <span className="spinner-border h-15px w-15px align-middle text-white-400"></span>
                      </>
                    )}
                  </span>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}
