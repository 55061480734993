export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function getModule(pathname: string) {
  return pathname.split(/[?#]/)[0].split('/')[1]
}

export function checkIsActive(pathname: string, url: string) {
  const module = getModule(pathname)

  if (!module || !url) {
    return false
  }

  return url.startsWith(`/${module}`)
}

export function checkIsInnerLinkActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)

  if (!current || !url) {
    return false
  }

  return current === url
}
