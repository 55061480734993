import React, {FC} from 'react'
import {Routes, Route, Navigate, BrowserRouter} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../features/errors/ErrorsPage'
import Login from '../features/auth/Login'
import {useAuth} from 'react-oidc-context'
import {App} from '../App'
import CallbackPage from '../features/auth/CallbackPage'
import FilePreview from '../features/shared/pages/FilePreview'
import FinancierRoutes from './FinancierRoutes'

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const auth = useAuth()
  const roles: string[] = auth?.user?.profile?.role as string[]

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          {auth.isAuthenticated ? (
            <>
              <Route path="/file-preview/:id" element={<FilePreview />} />
              <Route
                path="/*"
                element={roles?.includes('Financier') || roles?.includes('Grant Provider') ? <FinancierRoutes /> :
                  <PrivateRoutes />}
              />
            </>
          ) : (
            <>
              <Route path="/auth" element={<Login />} />
              <Route path="*" element={<Navigate to="/auth" />} />
              <Route path="/auth/callback" element={<CallbackPage />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}