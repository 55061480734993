import {createSelector, createEntityAdapter, EntityState} from '@reduxjs/toolkit'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import {RootState} from '../../../data/redux/Store'
import {INameIdPair} from '../shared/interfaces/INameIdPair'
import {AUTH_TOKEN_KEY, env} from '../../../data/constants'
import {
  IServiceProviderDetails,
  IServiceProviderContacts,
  IServiceProviderProjects,
  IProviderDocumentMetaData,
  ISearchProviderDocument,
  IProviderDocumentFolders,
} from './interfaces'
import {IPaginationMetadata} from '../shared/interfaces/IPaginationMetadata'
import {ISearchServiceProviders} from './interfaces/ISearchServiceProviders'
import {ISearchFolder} from '../companies/interfaces/ISearchFolder'
import {ISearchEntities} from '../shared/interfaces'

const ServiceProvidersAdapter = createEntityAdapter<IServiceProviderDetails>({
  selectId: (serviceProvider) => serviceProvider.id,
})
const SectorsAdapter = createEntityAdapter<INameIdPair>({
  selectId: (sector) => sector.id,
})
const ServiceProviderContactsAdapter = createEntityAdapter<IServiceProviderContacts>({
  selectId: (serviceProviderContacts) => serviceProviderContacts.id,
})
const ServiceProviderProjectsAdapter = createEntityAdapter<IServiceProviderProjects>({
  selectId: (serviceProviderProjects) => serviceProviderProjects.id,
})
const ProviderDocumentsAdapter = createEntityAdapter<IProviderDocumentMetaData>({
  selectId: (providerDocuments) => providerDocuments.id,
})
const ProviderDocumentFoldersAdapter = createEntityAdapter<IProviderDocumentFolders>({
  selectId: (providerDocumentFolders) => providerDocumentFolders.id,
})

const serviceProvidersInitialState = ServiceProvidersAdapter.getInitialState()
const sectorsInitialState = SectorsAdapter.getInitialState()
const serviceProviderContactsInitialState = ServiceProviderContactsAdapter.getInitialState()
const serviceProviderProjectsInitialState = ServiceProviderProjectsAdapter.getInitialState()
const providerDocumentsInitialState = ProviderDocumentsAdapter.getInitialState()
const providerDocumentFoldersInitialState = ProviderDocumentFoldersAdapter.getInitialState()

const baseQuery = fetchBaseQuery({
  baseUrl: env.ServiceProviders,
  validateStatus: (response, result) => {
    if (result && result.isError) return false
    if (response.status >= 200 && response.status <= 300) return true
    if (response.status >= 400 && response.status <= 500) return false
    return false
  },
  prepareHeaders: (headers, {getState}) => {
    const accessToken = localStorage.getItem(AUTH_TOKEN_KEY)
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }
    return headers
  },
})

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions)
  if (result.error) {
    const status = result.error.status

    throw new Error(`${status}`)
  }

  return result
}

export const ServiceProvidersApiSlice = createApi({
  reducerPath: 'serviceProvidersApi',
  baseQuery,
  tagTypes: [
    'ServiceProvider',
    'Sectors',
    'ServiceProviderContact',
    'ServiceProviderProject',
    'ServiceProviderDocument',
    'ServiceProviderDocumentFolder',
  ],
  endpoints: (builder) => ({
    getServiceProviders: builder.query<
      EntityState<IServiceProviderDetails> & {paginationMetadata: IPaginationMetadata},
      ISearchEntities
    >({
      query: (searchParams) => {
        const params = new URLSearchParams()
        if (searchParams.page) params.append('page', searchParams.page.toString())
        if (searchParams.pageSize) params.append('pageSize', searchParams.pageSize.toString())

        if (searchParams.searchTerm) params.append('searchTerm', searchParams.searchTerm)

        if (searchParams.countries) {
          searchParams.countries.forEach((country) => params.append('Countries', country))
        }
        if (searchParams.minYearsInOperation)
          params.append('minYearsInOperation', searchParams.minYearsInOperation.toString())
        if (searchParams.maxYearsInOperation)
          params.append('maxYearsInOperation', searchParams.maxYearsInOperation.toString())
        if (searchParams.sectors) {
          searchParams.sectors.forEach((sector) => params.append('Sectors', sector))
        }
        return {
          url: '/providers',
          params: params,
        }
      },
      transformResponse: (response: IServiceProviderDetails[], meta: FetchBaseQueryMeta) => {
        const entities = ServiceProvidersAdapter.setAll(serviceProvidersInitialState, response)
        const paginationMetadata = meta.response?.headers.get('X.Pagination')
        const parsedPaginationMetadata = paginationMetadata ? JSON.parse(paginationMetadata) : null
        return {
          ...entities,
          paginationMetadata: {
            totalCount: parsedPaginationMetadata?.TotalCount,
            page: parsedPaginationMetadata?.Page,
            pageSize: parsedPaginationMetadata?.PageSize,
            hasNextPage: parsedPaginationMetadata?.HasNextPage,
            hasPreviousPage: parsedPaginationMetadata?.HasPreviousPage,
          },
        }
      },
      providesTags: (result: EntityState<IServiceProviderDetails> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'ServiceProvider', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'ServiceProvider' as const, id})),
          ]
        } else return [{type: 'ServiceProvider', id: 'LIST'}]
      },
    }),
    getServiceProviderDetails: builder.query<EntityState<IServiceProviderDetails>, string>({
      query: (id) => `/providers/${id}`,
      transformResponse: (res: any) => {
        return ServiceProvidersAdapter.setOne(serviceProvidersInitialState, res)
      },

      providesTags: (result: EntityState<IServiceProviderDetails> | undefined) => {
        if (result?.ids) {
          return [...result.ids.map((id) => ({type: 'ServiceProvider' as const, id}))]
        } else return [{type: 'ServiceProvider', id: 'LIST'}]
      },
    }),
    addNewServiceProvider: builder.mutation({
      query: (initialServiceProviderData) => {
        return {
          url: '/providers',
          method: 'POST',
          body: initialServiceProviderData,
        }
      },
      invalidatesTags: (_result, _error, arg) => [{type: 'ServiceProvider', id: arg.id}],
    }),
    updateServiceProvider: builder.mutation({
      query: (updatedServiceProviderData) => ({
        url: `/providers/${updatedServiceProviderData.id}`,
        method: 'PUT',
        body: updatedServiceProviderData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'ServiceProvider', id: arg.id}],
      async onQueryStarted({id, ...rest}, {dispatch, queryFulfilled}) {
        const putResult = dispatch(
          ServiceProvidersApiSlice.util.updateQueryData(
            'getServiceProviderDetails',
            id,
            (draft) => {
              let serviceProvider = draft.entities[id]
              if (serviceProvider) {
                Object.assign(serviceProvider, rest)
              }
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          putResult.undo()
        }
      },
    }),
    deleteServiceProvider: builder.mutation({
      query: (id) => ({
        url: `/providers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'ServiceProvider', id: arg.id}],
    }),
    getSectors: builder.query<EntityState<INameIdPair>, void>({
      query: () => ({
        url: `/sectors`,
      }),
      transformResponse: (res: any) => {
        return SectorsAdapter.setAll(sectorsInitialState, res)
      },
      providesTags: (result: EntityState<INameIdPair> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'Sectors', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'Sectors' as const, id})),
          ]
        } else return [{type: 'Sectors', id: 'LIST'}]
      },
    }),
    getServiceProviderContacts: builder.query<EntityState<IServiceProviderContacts>, string>({
      query: (id) => ({
        url: `/provider/contacts/?providerId=${id}`,
      }),
      transformResponse: (res: any) => {
        const transformedResponse = res.map((contact: any) => ({
          serviceProviderContactPersonAddress: contact.address,
          serviceProviderContactPersonName: contact.name,
          serviceProviderContactPersonEmail: contact.email,
          serviceProviderContactPersonTelephone: contact.telephone,
          serviceProviderContactPersonPicture: contact.picture,
          serviceProviderId: contact.serviceProviderId,
          id: contact.id,
        }))
        return ServiceProviderContactsAdapter.setAll(
          serviceProviderContactsInitialState,
          transformedResponse,
        )
      },
      providesTags: (result: EntityState<IServiceProviderContacts> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'ServiceProviderContact', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'ServiceProviderContact' as const, id})),
          ]
        } else return [{type: 'ServiceProviderContact', id: 'LIST'}]
      },
    }),
    updateServiceProviderContact: builder.mutation({
      query: (updatedServiceProviderContactData) => ({
        url: `/provider/contacts/${updatedServiceProviderContactData.id}`,
        method: 'PUT',
        body: updatedServiceProviderContactData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'ServiceProviderContact', id: arg.id}],
      async onQueryStarted({id, ...rest}, {dispatch, queryFulfilled}) {
        const putResult = dispatch(
          ServiceProvidersApiSlice.util.updateQueryData(
            'getServiceProviderContacts',
            id,
            (draft) => {
              let serviceProviderContact = draft.entities[id]
              if (serviceProviderContact) {
                Object.assign(serviceProviderContact, rest)
              }
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          putResult.undo()
        }
      },
    }),
    addNewProject: builder.mutation({
      query: (initialProjectData) => ({
        url: '/projects',
        method: 'POST',
        body: initialProjectData,
      }),
      invalidatesTags: (_result, _error, _arg) => [
        {type: 'ServiceProviderProject', id: 'LIST'},
        {type: 'ServiceProvider', id: 'LIST'},
      ],
    }),
    getServiceProviderProjects: builder.query<EntityState<IServiceProviderProjects>, string>({
      query: (id) => ({
        url: `/projects/?providerId=${id}`,
      }),
      transformResponse: (res: any) => {
        return ServiceProviderProjectsAdapter.setAll(serviceProviderProjectsInitialState, res)
      },
      providesTags: (result: EntityState<IServiceProviderProjects> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'ServiceProviderProject', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'ServiceProviderProject' as const, id})),
          ]
        } else return [{type: 'ServiceProviderProject', id: 'LIST'}]
      },
    }),
    updateServiceProviderProject: builder.mutation({
      query: (updatedServiceProviderProjectData) => ({
        url: `/projects/${updatedServiceProviderProjectData.id}`,
        method: 'PUT',
        body: updatedServiceProviderProjectData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'ServiceProviderProject', id: arg.id}],
      async onQueryStarted({id, ...rest}, {dispatch, queryFulfilled}) {
        const putResult = dispatch(
          ServiceProvidersApiSlice.util.updateQueryData(
            'getServiceProviderProjects',
            id,
            (draft) => {
              let serviceProviderProject = draft.entities[id]
              if (serviceProviderProject) {
                Object.assign(serviceProviderProject, rest)
              }
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          putResult.undo()
        }
      },
    }),
    addNewServiceProviderDocument: builder.mutation({
      query: (documentMetaData) => ({
        url: '/files',
        method: 'POST',
        body: documentMetaData,
      }),
      invalidatesTags: (_result, _error) => [
        {type: 'ServiceProviderDocument', id: 'LIST'},
        {type: 'ServiceProviderDocumentFolder', id: 'LIST'},
      ],
    }),
    getServiceProviderDocuments: builder.query<
      EntityState<IProviderDocumentMetaData>,
      ISearchProviderDocument
    >({
      query: ({providerId, folderId}) => ({
        url: `/files/`,
        params: {FolderId: folderId, ProviderId: providerId},
      }),
      transformResponse: (res: any) => {
        return ProviderDocumentsAdapter.setAll(providerDocumentsInitialState, res)
      },
      providesTags: (result: EntityState<IProviderDocumentMetaData> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'ServiceProviderDocument', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'ServiceProviderDocument' as const, id})),
          ]
        } else return [{type: 'ServiceProviderDocument', id: 'LIST'}]
      },
    }),
    getServiceProviderDocumentFolders: builder.query<
      EntityState<IProviderDocumentFolders>,
      ISearchFolder
    >({
      query: ({id, providerId, searchTerm, includeOnlyParentFolders, parentFolderId}) => ({
        url: `/files/folders/`,
        params: {id, providerId, searchTerm, parentFolderId, includeOnlyParentFolders},
      }),
      transformResponse: (res: any) => {
        return ProviderDocumentFoldersAdapter.setAll(providerDocumentFoldersInitialState, res)
      },
      providesTags: (result: EntityState<IProviderDocumentFolders> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'ServiceProviderDocumentFolder', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'ServiceProviderDocumentFolder' as const, id})),
          ]
        } else return [{type: 'ServiceProviderDocumentFolder', id: 'LIST'}]
      },
    }),
    addNewServiceProviderDocumentFolder: builder.mutation({
      query: (documentFolderData) => ({
        url: '/files/folders',
        method: 'POST',
        body: documentFolderData,
      }),
      invalidatesTags: (_result, _error, arg) => [
        {type: 'ServiceProviderDocumentFolder', id: 'LIST'},
      ],
    }),
    updateServiceProviderDocumentFolder: builder.mutation({
      query: (documentFolderData) => ({
        url: `/files/folders/${documentFolderData.id}`,
        method: 'PUT',
        body: documentFolderData,
      }),
      invalidatesTags: (_result, _error, arg) => [
        {type: 'ServiceProviderDocumentFolder', id: 'LIST'},
      ],
    }),
    deleteDocumentFolder: builder.mutation({
      query: (id) => ({
        url: `/files/folders/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        {type: 'ServiceProviderDocumentFolder', id: arg.id},
      ],
    }),
    deleteServiceProviderDocument: builder.mutation({
      query: (id) => ({
        url: `/files/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        {type: 'ServiceProviderDocument', id: arg.id},
        {type: 'ServiceProviderDocumentFolder', id: arg.FolderId},
      ],
    }),
    checkNameAvailability: builder.mutation({
      query: ({name}) => ({
        url: '/providers/check-name',
        body: {name},
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useGetServiceProvidersQuery,
  useGetServiceProviderDetailsQuery,
  useAddNewServiceProviderMutation,
  useUpdateServiceProviderMutation,
  useDeleteServiceProviderMutation,
  useGetSectorsQuery,
  useCheckNameAvailabilityMutation,
  useGetServiceProviderContactsQuery,
  useGetServiceProviderProjectsQuery,
  useUpdateServiceProviderContactMutation,
  useUpdateServiceProviderProjectMutation,
  useAddNewProjectMutation,
  useAddNewServiceProviderDocumentMutation,
  useAddNewServiceProviderDocumentFolderMutation,
  useGetServiceProviderDocumentsQuery,
  useGetServiceProviderDocumentFoldersQuery,
  useDeleteServiceProviderDocumentMutation,
  useDeleteDocumentFolderMutation,
  useUpdateServiceProviderDocumentFolderMutation,
} = ServiceProvidersApiSlice

// Define a type for the Redux state

// returns the query result object
export const selectServiceProvidersResult = (state: RootState, searchParams: ISearchEntities) =>
  ServiceProvidersApiSlice.endpoints.getServiceProviders.select(searchParams)(state)

export const selectServiceProvidersData = (searchParams: ISearchEntities) =>
  createSelector(
    (state: RootState) => selectServiceProvidersResult(state, searchParams),
    (serviceProvidersResult) => serviceProvidersResult?.data ?? serviceProvidersInitialState,
  )

// Modify how you create selectors from the ServiceProvidersAdapter
export const createServiceProviderSelectors = (searchParams: ISearchEntities) => {
  const selectData = (state: RootState) => selectServiceProvidersData(searchParams)(state)
  return ServiceProvidersAdapter.getSelectors(selectData)
}
