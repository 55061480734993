import React, {useState} from 'react'
import EntityListSearch from '../../shared/components/EntityListSearch'
import FilterButton from '../../shared/components/FilterButton'
import Spinner from 'react-bootstrap/Spinner'
import {useNavigate, useParams} from 'react-router-dom'
import {CallsCard} from '../components/CallsCard'
import useAuthToken from '../../../../hooks/useAuthToken'
import {useGetFinancierCallsQuery} from '../FinanciersApiSlice'
import {IFinancierCall} from '../interfaces'
import {ListPageFilters} from '../../shared/modals/ListPageFilters'
import {useGetSectorsQuery} from '../../companies/CompaniesApiSlice'
import {INameIdPair} from '../../shared/interfaces'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'

const Calls = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const filters = useSelector((state: RootState) => state.settings.callFilters)
  const navigate = useNavigate()
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }
  const {financierId} = useParams<{financierId: string}>()

  const isTokenAvailable = useAuthToken()

  const {data, error, isLoading, isFetching, isSuccess, isError} = useGetFinancierCallsQuery(
    {id: financierId || '', searchParams: filters},
    {skip: !isTokenAvailable},
  )
  const {data: sectorsState} = useGetSectorsQuery()
  const companySectorsList = Object.values(sectorsState?.entities || {}).filter(
    (type): type is INameIdPair => Boolean(type),
  )

  const callsList = Object.values(data?.entities?.entities || {}).filter(Boolean) as IFinancierCall[]


  const handleFiltersModal = () => {
    setShowFiltersModal(!showFiltersModal)
  }

  const handleResetSelectedPage = () => {
    setCurrentPage(1)
  }


  let content
  if (isFetching) {
    content = (
      <div className="d-flex justify-content-center flex-column gap-3 align-items-center">
        <Spinner animation="border" variant="danger" />
        <p className="pt-0">Loading...</p>
      </div>
    )
  } else if (isSuccess) {
    content = callsList.length > 0 ?
      (
        <div className="row">
          {callsList.map((call, index) => (
            <div className={`col-12 col-md-6 col-lg-4`} key={index}>
              <CallsCard call={call} />
            </div>
          ))}
        </div>
      ) : (
        <div className="d-flex justify-content-right align-items-right vh-100">
          <em>There are no calls listed yet.</em>
        </div>
      )
  } else if (isError) {
    content = (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <em>Unknown error occurred.</em>
      </div>
    )
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <span className="fs-6 fw-bold">Calls</span>
        <div className="d-flex gap-2">
          {/*<EntityListSearch*/}
          {/*  hasWhiteBackground={true}*/}
          {/*  setSearchTerm={setSearchTerm}*/}
          {/*  handlePageChange={handlePageChange}*/}
          {/*/>*/}
          <FilterButton
            showFilters={showFiltersModal}
            handleFilterButtonClick={handleFiltersModal}
            hasWhiteBackground={true}
          />
        </div>
      </div>
      <div className="d-flex flex-column">
        <div className="flex-grow-1">{content}</div>
      </div>
      <ListPageFilters
        showModal={showFiltersModal}
        handleClose={handleFiltersModal}
        companySectorsList={companySectorsList}
        includeInvestmentAsk={false}
        includeFinancingTypes={false}
        includeYearsOfOperation={false}
        includeStartAndEndDate={true}
        includeSectors={false}
        entities="Calls"
        handleResetSelectedPage={handleResetSelectedPage}
      />
    </>
  )
}

export {Calls}