import React, {FC, useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import {KTSVG} from '../../../../_theme/helpers'
import {useParams} from 'react-router-dom'
import {useGetCompanyDetailsQuery, useUpdateCompanyMutation} from '../CompaniesApiSlice'

type Props = {
  showModal: boolean
  handleClose: () => void
}

export const UpdateOnboardingChecklist: FC<Props> = ({handleClose, showModal}) => {
  const onboardingChecklist = [
    'Successfully Created Account',
    'Submitted standard business plan',
    'Submitted audited financial statements',
    'Submitted management accounts',
    'Submitted pitchdeck',
    'Submitted investor memos',
  ]
  const {id} = useParams<{id: string}>() as {id: string}
  const {data: companyState, isLoading} = useGetCompanyDetailsQuery(id)
  const [updateCompany, {isLoading: isUpdatingCompany}] = useUpdateCompanyMutation()
  const companyDetails = companyState?.entities[id]

  const [checklistState, setChecklistState] = useState<number[]>([])

  useEffect(() => {
    if (companyDetails) {
      setChecklistState(companyDetails?.onboardingStatus.split(',').map(Number))
    }
  }, [companyDetails])

  const handleCheckboxChange = (index: number) => {
    setChecklistState(prevState => {
      const newState = [...prevState]
      newState[index] = newState[index] === 1 ? 0 : 1
      return newState
    })
  }

  const handleSave = async () => {
    const updatedCompanyDetails = {
      ...companyDetails,
      onboardingStatus: checklistState.join(','),
    }
    try {
      await updateCompany(updatedCompanyDetails).unwrap()
      setChecklistState([])
      handleClose()
    } catch (error) {
      handleClose()
    }
  }

  return (
    <>
      <Modal
        id="updateOnboardingChecklist"
        aria-hidden={true}
        dialogClassName="h-auto mt-20 w-350px"
        tabIndex={-1}
        show={showModal}
        onHide={handleClose}
        centered
      >
        <div className="modal-content" role="document">
          <div className="modal-header">
            <h5 className="modal-title ps-1" id="updateOnboardingChecklist">
              Update Onboarding Status
            </h5>
            <div
              className="btn btn-sm btn-icon btn-active-color-danger"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
            </div>
          </div>
          <div className="modal-body d-flex flex-column align-items-start justify-content-center">
            {onboardingChecklist.map((checklist, index) => (
              <div
                key={index}
                className="form-check form-check-custom form-check-solid form-check-lg form-check-danger pb-3"
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`flexCheckboxLg-${index}`}
                  checked={checklistState[index] === 1}
                  onChange={() => handleCheckboxChange(index)}
                />
                <label className="form-check-label" htmlFor={`flexCheckboxLg-${index}`}>
                  {checklist}
                </label>
              </div>
            ))}
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-danger btn-sm w-100"
              disabled={isLoading}
              onClick={handleSave}
            >
              <span className="indicator-label">
                {!isUpdatingCompany ? (
                  'Submit Changes'
                ) : (
                  <span className="spinner-border h-15px w-15px align-middle text-white-400"></span>
                )}
              </span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}