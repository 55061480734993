import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {KTSVG} from '../../../../_theme/helpers'
import {useParams} from 'react-router-dom'
import {ICreateDocument} from '../../documents'
import {useAddNewDocumentMutation} from '../../documents/DocumentsApiSlice'

type Props = {
  showUploadDocumentModal: boolean
  handleUploadFileModal: () => void
  handleUploadFile: (res: any) => void
  isSavingDocumentMetaData: boolean
}

export const UploadDocument: React.FC<Props> = ({
                                                  handleUploadFileModal,
                                                  handleUploadFile,
                                                  showUploadDocumentModal,
                                                  isSavingDocumentMetaData,
                                                }) => {
  const {id} = useParams<{id: string}>() as {id: string}
  const [selectedFiles, setSelectedFiles] = useState<ICreateDocument[]>([])
  const [createDocument, {isLoading: isCreatingDocument}] = useAddNewDocumentMutation()
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files ? event.target.files[0] : null
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result as string
        const base64Content = base64String.split(',')[1]
        const fileObject: ICreateDocument = {
          fileName: file.name,
          fileType: file.type,
          fileContent: base64Content,
          dateUploaded: new Date().toISOString(),
          fileSize: file.size,
        }
        setSelectedFiles((prevFiles) => ({
          ...prevFiles,
          fileObject,
        }))
      }
      reader.readAsDataURL(file)
    }
  }
  const handleUpload = async () => {
    const documents = Object.values(selectedFiles)
    const payload = {files: documents}
    try {
      let response = await createDocument(payload).unwrap()
      handleUploadFile(response)
      setSelectedFiles([])
    } catch (error) {
    }
  }

  return (
    <>
      <Modal
        id="editFinancialsModal"
        aria-hidden={true}
        dialogClassName="h-auto mt-20"
        tabIndex={-1}
        show={showUploadDocumentModal}
        onHide={handleUploadFileModal}
        centered
      >
        <div className="modal-content" role="document">
          <div className="modal-header">
            <h5 className="modal-title ps-1" id="editLogoModalLabel">
              Upload File
            </h5>
            <div
              className="btn btn-sm btn-icon btn-active-color-danger"
              data-bs-dismiss="modal"
              onClick={handleUploadFileModal}
            >
              <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
            </div>
          </div>
          <div className="modal-body d-flex align-items-center justify-content-center">
            <input
              type="file"
              className="form-control"
              id={`file-input`}
              onChange={(event) => handleFileChange(event)}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline btn-sm btn-outline-danger btn-active-light-danger"
              onClick={handleUploadFileModal}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-danger d-flex align-items-center justify-content-center btn-sm"
              onClick={handleUpload}
              disabled={isCreatingDocument || isSavingDocumentMetaData}
            >
              <span className="indicator-label">
                {!isCreatingDocument && !isSavingDocumentMetaData ? (
                  'Save'
                ) : (
                  <>
                    <span className="spinner-border h-15px w-15px align-middle text-white-400"></span>
                  </>
                )}
              </span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
