import {FC, useEffect, useMemo, useState} from 'react'
import Search from '../../shared/components/Search'
import {fileExtensionIcons} from '../../shared/constants'
import {useParams} from 'react-router-dom'
import {printDateWithOrdinal} from '../../../../utils/dateHelpers'
import {formatBytes} from '../../../../utils/numberHelpers'
import {
  useAddNewFinancierDocumentMutation,
  useDeleteFinancierDocumentMutation,
  useGetFinancierDocumentsQuery,
} from '../FinanciersApiSlice'
import {IFinancierDocumentMetaData} from '../interfaces'
import {OptionsPopup} from '../../shared/components/OptionsPopup'
import {useDeleteDocumentMutation, useGetDocumentsQuery} from '../../documents/DocumentsApiSlice'
import Loading from '../../shared/components/Loading'
import {DeleteModal} from '../../shared/components/DeleteModal'

type FinancierDocumentsListProps = {
  selectedFolder: {index: number; id: string; folderName: string}
  handleFolderToggle: () => void
  showModal: boolean
  handleClose: () => void
}
const FinancierDocumentsList: FC<FinancierDocumentsListProps> = ({
                                                                   selectedFolder,
                                                                   handleFolderToggle,
                                                                 }) => {
  const {id} = useParams<{id: string}>() as {id: string}
  const [searchTerm, setSearchTerm] = useState('')
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null)
  const [showFileOptionsModal, setShowFileOptionsModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState<{id: string; documentAssetId: string}>({
    id: '',
    documentAssetId: '',
  })
  const [selectedFolderId, setSelectedFolderId] = useState('')
  const [saveDocumentMetaData] = useAddNewFinancierDocumentMutation()
  const [triggerDownload, setTriggerDownload] = useState(false)

  const {data: documentsState, isFetching: gettingFile} = useGetDocumentsQuery(
    [selectedFile.documentAssetId],
    {
      skip: !triggerDownload,
    },
  )
  const file = useMemo(
    () => documentsState?.entities[selectedFile.documentAssetId],
    [documentsState, selectedFile.documentAssetId],
  )
  useEffect(() => {
    if (triggerDownload && file) {
      const byteCharacters = atob(file.fileContent)
      const byteArray = new Uint8Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i)
      }
      const blob = new Blob([byteArray], {type: file.fileType})
      const blobUrl = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = blobUrl
      link.download = file.fileName
      link.click()

      URL.revokeObjectURL(blobUrl)
      setTriggerDownload(false)
      setOpenDropdownId(null)
    }
  }, [triggerDownload, file])
  const [deleteFinancierDocument, {isLoading: isDeletingFinancierDocument}] =
    useDeleteFinancierDocumentMutation()
  const [deleteDocument, {isLoading: isDeletingDocument}] = useDeleteDocumentMutation()
  const handleFolderOptionsPopup = () => {
    if (showFileOptionsModal) setShowFileOptionsModal(false)
  }

  const handleFileOptionsClick = (file: {id: string; documentAssetId: string}) => {
    setSelectedFile(file)
    setOpenDropdownId(file.id)
  }
  const handleUploadFileModal = () => {
    setShowUploadDocumentModal(!showUploadDocumentModal)
  }
  const handleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  const handleDelete = async () => {
    try {
      await Promise.all([
        deleteDocument(selectedFile.documentAssetId).unwrap(),
        deleteFinancierDocument({id: selectedFile.id, folderId: selectedFolder.id}).unwrap(),
      ])
      setSelectedFile({id: '', documentAssetId: ''})
      handleFolderOptionsPopup()
      handleDeleteModal()
    } catch (error) {
    }
  }
  const handlePreview = () => {
    window.open(`/file-preview/${selectedFile.documentAssetId}`, '_blank')
  }
  const handleDownload = () => {
    setTriggerDownload(true)
  }
  const {data: financierDocumentsState, isFetching: isGettingDocuments} =
    useGetFinancierDocumentsQuery({
      financierId: id,
      folderId: selectedFolder.id,
      searchTerm,
    })
  const financierDocuments: IFinancierDocumentMetaData[] = Object.values(
    financierDocumentsState?.entities?.entities || {},
  ) as IFinancierDocumentMetaData[]
  const documents = financierDocuments
    ? financierDocuments.map((document) => {
      const [name, extension] = document.fileName.split('.')
      return {
        ...document,
        fileName: name,
        fileExtension: extension,
        uploadedDate: printDateWithOrdinal(document.dateUploaded),
        size: formatBytes(document.fileSize),
      }
    })
    : []
  const handleUploadFile = async (res: any, selectedFolderId: string) => {
    try {
      const documentsMetaData = res.map((doc: any) => {
        return {
          document_asset_id: doc.id,
          financier_id: id,
          file_name: doc.fileName,
          file_type: doc.fileType,
          file_content: 'Remove Property',
          file_size: doc.fileSize,
          folder: selectedFolderId,
        }
      })
      await saveDocumentMetaData(documentsMetaData).unwrap()
    } catch (err) {
    }
  }
  return (
    <>
      <div className="card card-flush card-bordered mt-4 mb-4">
        <div className="card-body py-5">
          <div className="d-flex align-items-center justify-content-between position-relative">
            <div>
              <span
                className="fs-6 fw-bold hover-bg text-hover-danger"
                onClick={handleFolderToggle}
              >
                <i className="fas fa-arrow-left fs-8 me-1 text-dark"></i> Documents
              </span>
              /<span className="fs-7 fw-bold">{selectedFolder?.folderName}</span>
            </div>
            <div className="d-flex align-items-center gap-3">
              <Search setSearchTerm={setSearchTerm} />
              <div onClick={() => setSelectedFolderId(selectedFolder?.id)}>
                <OptionsPopup
                  selectedEntity={selectedFolderId}
                  // setSelectedEntity={setSelectedFileId}
                  isButton={true}
                  includeCreateFolder={true}
                  includeUploadFile={true}
                  handleUploadFileModal={handleUploadFileModal}
                  // handleCreateFolderModal={handleCreateFolderModal}
                  // showCreateFolderModal={showCreateFolderModal}
                  showUploadDocumentModal={showUploadDocumentModal}
                  handleUploadFile={handleUploadFile}
                />
              </div>
            </div>
          </div>
          <div className="table-like">
            <div className="table-header fw-bold fs-6 border-bottom pb-4">
              <div className="header-item">Name</div>
              <div className="header-item">Size</div>
              <div className="header-item">Uploaded On</div>
              <div className="header-item"></div>
            </div>
            {isGettingDocuments ? (
              <Loading />
            ) : (
              documents.map((document, index) => (
                <div
                  key={index}
                  className={`table-row ${index < documents.length - 1 ? 'border-bottom' : ''}`}
                >
                  <div className="row-item">
                    <div className="d-flex align-items-center gap-1">
                      <i className={`fa ${fileExtensionIcons[document.fileExtension]} me-2`}></i>
                      <span>{document.fileName}</span>
                    </div>
                  </div>
                  <div className="row-item">{document.size}</div>
                  <div className="row-item">{document.uploadedDate}</div>
                  <div className="row-item">
                    <div className="position-relative">
                      <div
                        className="d-flex justify-content-end p-2 cursor-pointer"
                        onClick={() =>
                          handleFileOptionsClick({
                            id: document?.id,
                            documentAssetId: document?.documentAssetId,
                          })
                        }
                      >
                        <OptionsPopup
                          selectedEntity={selectedFile}
                          setSelectedEntity={setSelectedFile}
                          handleDelete={handleDelete}
                          showDeleteModal={showDeleteModal}
                          deletingEntity={isDeletingDocument}
                          handleDeleteModal={handleDeleteModal}
                          entity="File"
                          handlePreview={handlePreview}
                          includeFilePreviewOption={true}
                          includeDeleteOption={true}
                          includeDownloadOption={true}
                          gettingFile={gettingFile && openDropdownId === document.id}
                          triggerDownload={triggerDownload}
                          handleDownload={handleDownload}
                          isEllipsis={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        isLoading={isDeletingDocument}
        handleDelete={handleDelete}
        item={'File'}
        handleClose={handleDeleteModal}
      />
    </>
  )
}

export default FinancierDocumentsList
