export const ChartCommonOptions = (title: string, categories: string[], data: number[], colors: string[], type: string = 'column', useConstantColor: boolean = false) => ({
  chart: {type: type, height: 300},
  title: {text: title},
  xAxis: {categories: categories},
  yAxis: {title: {text: ''}},
  series: [{name: '', data: data, colorByPoint: true}],
  colors: useConstantColor ? [colors[0]] : colors,
})



