export const PieChartOptions = (
  title: string,
  data: {name: string; y: number; color: string}[],
) => ({
  chart: {type: 'pie', height: 300},
  title: {text: title},
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b> <br/> {point.percentage:.1f} %',
      },
      size: '70%',
    },
  },
  series: [
    {
      name: 'Applicants',
      colorByPoint: true,
      data: data, // Pass the formatted pie chart data
    },
  ],
  colors: data.map((item) => item.color), // Set the slice colors
})