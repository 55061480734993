import React, {useState} from 'react'
import africaMapData from '@highcharts/map-collection/custom/africa.geo.json'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HighchartsMap from 'highcharts/modules/map'
import {log} from '@amcharts/amcharts4/.internal/core/utils/Log'

HighchartsMap(Highcharts)

interface District {
  name: string;
  count: number;
}

interface Region {
  name: string;
  count: number;
}

interface CountryData {
  name: string;
  value: number;
  regions: Region[];
  districts: District[];
}

const MapDashboard = ({countryData}: {countryData: CountryData[]}) => {
  const [mapData, setMapData] = useState(africaMapData)
  const [mapTitle, setMapTitle] = useState('Distribution of Applicants by Region')
  const [seriesData, setSeriesData] = useState([])

  const countryValuesMap = countryData.reduce((acc, country) => {
    acc[country.name] = country
    return acc
  }, {} as {[key: string]: CountryData})

  const mapOptions = {
    chart: {
      map: mapData,
    },
    title: {
      text: mapTitle,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom',
      },
    },
    tooltip: {
      pointFormat: '{point.name}: {point.value}',
    },
    colorAxis: {
      min: 0,
      max: 10,
      type: 'linear',
      minColor: '#777777',
      maxColor: '#FF0000',
    },
    series: [
      {
        name: 'Africa',
        data: seriesData.length > 0 ? seriesData : africaMapData.features.map((feature) => {
          const countryName = feature.properties.name
          const countryDataItem = countryValuesMap[countryName]
          const countryValue = countryDataItem ? countryDataItem.value : null
          return {
            key: feature.properties['hc-key'],
            name: countryName,
            value: countryValue,
            color: countryValue ? undefined : null,
          }
        }),
        joinBy: ['hc-key', 'key'],
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          format: '{point.name}',
          style: {
            fontWeight: 'bold',
            color: 'black',
            textOutline: '1px white',
          },
        },
        events: {
          click: (event: any) => handleRegionClick(event.point.key, event.point.name),
        },
      },
    ],
  }


  const handleRegionClick = async (regionKey: any, regionName: any) => {
    try {
      const selectedCountry = countryValuesMap[regionName] // Get the selected country's data
      if (!selectedCountry) {
        return
      }
      const countryMapData = await import(`@highcharts/map-collection/countries/${regionKey}/${regionKey}-all.geo.json`)
      const districtData = selectedCountry.districts
      setMapData(countryMapData.default)
      setSeriesData(countryMapData.default.features.map((feature: any) => {
        const districtName = feature.properties.name
        const districtInfo = districtData.find(d => d.name === districtName) // Check if the district exists in the data
        const districtValue = districtInfo ? districtInfo.count : 0
        return {
          key: feature.properties['hc-key'],
          name: districtName,
          value: districtValue,
          color: districtValue > 0 ? undefined : '#fff',
        }
      }))
      setMapTitle(`Regions in ${regionName}`)


    } catch (error) {
      console.error(`Map data for ${regionName} not found.`)
      console.log(error)
    }
  }
  return (
    <div className="border-solid">
      <HighchartsReact highcharts={Highcharts} constructorType={'mapChart'} options={mapOptions} />
      <button className="btn btn-outline btn-outline-danger btn-active-light-danger" onClick={() => {
        setMapData(africaMapData)
        setSeriesData([])
        setMapTitle('Distribution of Applicants by Region')
      }}>
        Reset
      </button>
    </div>
  )
}

export default MapDashboard